<template>
	<div>
		<CRow v-if="!$store.state.dashboardData" class="d-flex justify-content-center">
			<div class="my-5 d-flex flex-column align-items-center justify-content-center">
				<div class="spinner-border text-primary mb-3" style="width: 40px; height: 40px;" role="status">
				</div>
				<p class="mb-0 text-primary">{{ $t('loadingInfo') }}</p>
			</div>
		</CRow>
		<CRow v-else>
			<CCol sm="6" lg="3">
				<CWidgetDropdown color="primary" :header="toThousandFilter($store.state.dashboardData.creditos)" :text="$t('creditsQty')">
					<!-- <template #default>
						<CDropdown
							color="transparent p-0"
							placement="bottom-end"
						>
							<template #toggler-content>
								<CIcon name="cil-settings"/>
							</template>
							<CDropdownItem>Ver Listado</CDropdownItem>
						</CDropdown>
					</template> -->
					<template #footer>
						<!-- <CChartLineSimple
							pointed
							class="mt-3 mx-3"
							style="height:70px"
							:data-points="[65, 59, 84, 84, 51, 55, 40]"
							point-hover-background-color="primary"
							label="Members"
							labels="months"
						/> -->
						<div class="my-3"></div>
					</template>
				</CWidgetDropdown>
			</CCol>
			<CCol sm="6" lg="3">
				<CWidgetDropdown color="info" :header="toThousandFilter($store.state.dashboardData.evaluados)" :text="$t('dashUsuarios')">
					<template #default>
						<CDropdown
							color="transparent p-0"
							placement="bottom-end"
							:caret="false"
						>
							<template #toggler-content>
								<CIcon name="cil-settings"/>
							</template>
							<CDropdownItem to="/users">Ver Listado</CDropdownItem>
							<CDropdownItem @click="$store.commit('setNewUserModal', true)" >Nuevo Usuario Cliente</CDropdownItem>
						</CDropdown>
					</template>
					<template #footer>
						<div class="my-3"></div>
					</template>
				</CWidgetDropdown>
			</CCol>
			<CCol sm="6" lg="3">
				<CWidgetDropdown color="warning" :header="toThousandFilter($store.state.dashboardData.evaluaciones)" :text="$t('evaluatedUsers')">
					<template #default>
						<CDropdown
							color="transparent p-0"
							placement="bottom-end"
						>
							<template #toggler-content>
								<CIcon name="cil-settings"/>
							</template>
							<CDropdownItem to="/evaluations">Ver Listado</CDropdownItem>
							<CDropdownItem @click="$store.commit('setNewEvaluationModal', true)" >Nueva Evaluación</CDropdownItem>
						</CDropdown>
					</template>
					<template #footer>
						<div class="my-3"></div>
					</template>
				</CWidgetDropdown>
			</CCol>
			<CCol sm="6" lg="3">
				<CWidgetDropdown color="danger" :header="toThousandFilter($store.state.dashboardData.evaluadores)" :text="$t('dashNetworkUsers')">
					<template #default>
						<CDropdown
							color="transparent p-0"
							placement="bottom-end"
						>
							<template #toggler-content>
							<CIcon name="cil-settings"/>
							</template>
							<CDropdownItem to="/networkusers">Ver Listado</CDropdownItem>
							<CDropdownItem @click="$store.commit('setNewNetworkUserModal', true)" >Nuevo Colaborador</CDropdownItem>
						</CDropdown>
					</template>
					<template #footer>
						<div class="my-3"></div>
					</template>
				</CWidgetDropdown>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { CChartLineSimple, CChartBarSimple } from '../charts/index.js'

export default {
  name: 'WidgetsDropdown',
  components: { CChartLineSimple, CChartBarSimple },
	methods: {
		toThousandFilter(num) {
			return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, '.'))
		},
	}
}
</script>
